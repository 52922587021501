<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row class="mb-1">
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <b-form-input
              v-model="filterOrder"
              class="d-inline-block mr-50 advance-search"
              :placeholder="$t('Order')"
            />

            <b-form-input
              v-model="filterInvoice"
              class="d-inline-block mr-50 advance-search"
              :placeholder="$t('Invoice')"
            /> -->
            <!-- <b-form-input
              v-model="filterStartDate"
              class="d-inline-block mr-50 advance-search"
              :placeholder="$t('Start Date')"
            />
            <b-form-input
              v-model="filterEndDate"
              class="d-inline-block mr-50 advance-search"
              :placeholder="$t('End Date')"
            /> -->
            <b-form-input
              type="date"
              class="invoice-filter-select mr-2"
              v-model="filterStartDate"
              id="invoice-data-id"
              :placeholder="$t('YYYY-MM-DD')"
            />
            <b-form-input
              type="date"
              class="invoice-filter-select"
              v-model="filterEndDate"
              id="invoice-data-id"
              :placeholder="$t('YYYY-MM-DD')"
            />
            <!-- <label class="mr-50">{{ $t("Entry Type") }}</label>
            <v-select
              v-model="filterEntryType"
              dir="ltr"
              :options="entrytypeOptions"
              :placeholder="$t('Entry Type')"
              class="entryttype-selector d-inline-block mx-50"
              label="label"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ $t(label) }}
                </span>
              </template>
              <template #option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ $t(label) }}
                </span>
              </template>
            </v-select>
            &nbsp;&nbsp;

            <label class="mr-50">{{ $t("Year") }}</label>
            <v-select
              v-model="filterYear"
              dir="ltr"
              :options="YearOptions"
              :placeholder="$t('Year')"
              class="year-selector d-inline-block mx-50"
            />&nbsp;&nbsp; -->
          </b-col>
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <b-form-input
              v-model="filter"
              class="d-inline-block mr-50 w-75"
              :placeholder="$t('Advance search')"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Pagesize") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />&nbsp;&nbsp;
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mr-1"
              id="first"
              @click="whichPage"
            >
              {{ $t("First") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mr-1"
              id="prior"
              @click="whichPage"
            >
              {{ $t("Prior") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mr-1"
              id="next"
              @click="whichPage"
            >
              {{ $t("Next") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              id="last"
              @click="whichPage"
            >
              {{ $t("Last") }}
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="ladgerdata"
        :filter="filter"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="There is no data"
      >
        <!-- <template #cell(Document_No)="data">
          {{ $t("labels.Document_No") }}
        </template> -->

        <template #cell(entryType)="data">
          <div v-if="data.item.remainder == 0">
            <span style="color:green;">{{ data.item.entryType }}</span>
          </div>
          <div v-else-if="data.item.remainder == data.item.amount">
            <span style="color:red;">{{ data.item.entryType }}</span>
          </div>
          <div v-else>
            <span style="color:orange;">{{ data.item.entryType }}</span>
          </div>
        </template>

        <template #cell(date)="data">
          <div v-if="data.item.remainder == 0">
            <span style="color:green;">{{ data.item.date }}</span>
          </div>
          <div v-else-if="data.item.remainder == data.item.amount">
            <span style="color:red;">{{ data.item.date }}</span>
          </div>
          <div v-else>
            <span style="color:orange;">{{ data.item.date }}</span>
          </div>
        </template>

        <template #cell(text)="data">
          <div v-if="data.item.remainder == 0">
            <span style="color:green;">{{ data.item.text }}</span>
          </div>
          <div v-else-if="data.item.remainder == data.item.amount">
            <span style="color:red;">{{ data.item.text }}</span>
          </div>
          <div v-else>
            <span style="color:orange;">{{ data.item.text }}</span>
          </div>
        </template>

        <template #cell(invoiceNumber)="data">
          <div v-if="data.item.remainder == 0">
            <span style="color:green;">{{ data.item.invoiceNumber }}</span>
          </div>
          <div v-else-if="data.item.remainder == data.item.amount">
            <span style="color:red;">{{ data.item.invoiceNumber }}</span>
          </div>
          <div v-else>
            <span style="color:orange;">{{ data.item.invoiceNumber }}</span>
          </div>
        </template>

        <template #cell(amount)="data">
          <div v-if="data.item.remainder == 0">
            <span style="color:green;">{{ data.item.amount }}</span>
          </div>
          <div v-else-if="data.item.remainder == data.item.amount">
            <span style="color:red;">{{ data.item.amount }}</span>
          </div>
          <div v-else>
            <span style="color:orange;">{{ data.item.amount }}</span>
          </div>
        </template>

        <template #cell(currency)="data">
          <div v-if="data.item.remainder == 0">
            <span style="color:green;">{{ data.item.currency }}</span>
          </div>
          <div v-else-if="data.item.remainder == data.item.amount">
            <span style="color:red;">{{ data.item.currency }}</span>
          </div>
          <div v-else>
            <span style="color:orange;">{{ data.item.currency }}</span>
          </div>
        </template>

        <template #cell(remainder)="data">
          <div v-if="data.item.remainder == 0">
            <span style="color:green;">{{ data.item.remainder }}</span>
          </div>
          <div v-else-if="data.item.remainder == data.item.amount">
            <span style="color:red;">{{ data.item.remainder }}</span>
          </div>
          <div v-else>
            <span style="color:orange;">{{ data.item.remainder }}</span>
          </div>
        </template>
      </b-table>
      <!-- <div class="text-center" v-if="!nodata">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div> -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t("Showing") }} {{ dataMeta.from }} {{ $t("to") }}
              {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
              {{ $t("entries") }}</span
            >
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "axios";
import i18n from "@/libs/i18n/index";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ladgerdata: [],
      paginationdata: [],
      tableColumns: [
        { key: "Document_No", label: i18n.t("Document_No"), sortable: true },
        // { key: "Entry_No", label: i18n.t("Entry_No"), sortable: true },
        {
          key: "Customer_Name",
          label: i18n.t("Customer_Name"),
          sortable: true,
        },
        {
          key: "Currency_Code",
          label: i18n.t("Currency_Code"),
          sortable: true,
        },
        {
          key: "Credit_Amount",
          label: i18n.t("Credit_Amount"),
          sortable: true,
        },
        { key: "Debit_Amount", label: i18n.t("Debit_Amount"), sortable: true },
        { key: "Due_Date", label: i18n.t("Due_Date"), sortable: true },
        { key: "Posting_Date", label: i18n.t("Posting_Date"), sortable: true },
      ],
      filter: "",
      perPage: 10,
      currentPage: 1,
      totalLedger: "",
      filterEntryType: "All",
      filterYear: new Date().getFullYear(),
      filteroffset: 0,
      perPageOptions: [10, 20, 40, 60, 80, 100],
      YearOptions: [],
      filterStartDate: "",
      filterEndDate: "",
      nodata: false,

      // entrytypeOptions : [
      //     {
      //         text : "All",
      //         value : "All"
      //     },
      //     {
      //         text : "invoices",
      //         value : "invoices"
      //     },
      //     {
      //         text : "payments",
      //         value : "payments"
      //     }
      // ],
      entrytypeOptions: ["All", "invoices", "payments"],
      dataMeta: { from: 1, to: this.perPage, of: this.totalLedger },
    };
  },
  updated() {
    const f = this.perPage * (this.currentPage - 1);
    this.dataMeta.from = f + 1;
    var checkrecords = this.perPage * this.currentPage;
    if (checkrecords > this.totalLedger) {
      this.dataMeta.to = this.totalLedger;
    } else {
      this.dataMeta.to = this.perPage * this.currentPage;
    }
    this.dataMeta.of = this.totalLedger;
  },
  watch: {
    perPage: function() {
      this.getLedgerlist();
    },
    filterYear: function(val) {
      this.filterYear = val;
      this.getLedgerlist();
    },
    filterEntryType: function(val) {
      this.filterEntryType = val;
      this.getLedgerlist();
    },
    currentPage: function(val) {
      this.currentPage = val;
      this.filteroffset = val - 1;
      this.getLedgerlist();
    },
    filterStartDate: function(val) {
      this.filterStartDate = val;
      this.getLedgerlist();
    },
    filterEndDate: function(val) {
      this.filterEndDate = val;
      this.getLedgerlist();
    },
  },
  created() {
    this.mytoken = localStorage.getItem("token");
    if (this.mytoken == "" || this.mytoken == null) {
      localStorage.setItem("token", "");
      this.$router.push({ name: "login" });
    } else {
      var config = {
        method: "get",
        url:
          "https://engine.netsupport.dk:7080/account/v1/checktokendebtorbc/" +
          this.mytoken,
      };
      axios(config)
        .then((response) => {
          console.log("res", response);
          if (response.data.token.status == true) {
            if (localStorage.getItem("pagesize")) {
              this.perPage = localStorage.getItem("pagesize");
            } else {
              this.perPage = 10;
            }

            this.getLedgerlist();
            this.getLastyears();
          }
        })
        .catch(function(error) {
          if (error.response.status == 404) {
            localStorage.setItem("token", "");
            localStorage.removeItem("username");
            window.location.href = "/login";
          }
        });
    }
  },
  mounted() {
    const currentDate = new Date();

    const year = currentDate.getFullYear() - 1;
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    this.filterStartDate = formattedDate;
    // this.filterStartDate = "2022-01-01";

    const currentDate1 = new Date();

    const year1 = currentDate.getFullYear();
    const month1 = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day1 = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate1 = `${year1}-${month1}-${day1}`;
    this.filterEndDate = formattedDate1;
  },
  methods: {
    getLastyears() {
      const currentYear = new Date().getFullYear();
      var testyear = currentYear - 2;
      for (let i = currentYear; i >= testyear; i--) {
        this.YearOptions.push(i);
      }
    },

    getLedgerlist() {
      console.log("getLedgerlist");
      this.mytoken = localStorage.getItem("token");

      var config = {
        method: "get",
        url:
          "https://engine.netsupport.dk:7080/bcportal/v1/payments/" +
          this.mytoken +
          "?offset=" +
          this.filteroffset +
          "&pagesize=" +
          this.perPage +
          "&startdate=" +
          this.filterStartDate +
          "&enddate=" +
          this.filterEndDate,
      };
      axios(config)
        .then((response) => {
          console.log("reslist", response);
          if (response.data.payments.length > 0) {
            this.nodata = false;
          } else {
            this.nodata = true;
          }

          //console.log(response.data.economic.collection);
          // this.totalLedger = response.data.economic.pagination.results;
          this.ladgerdata = JSON.parse(JSON.stringify(response.data.payments));

          // this.paginationdata = response.data.economic.pagination;

          // this.currentPage = response.data.economic.pagination.skipPages + 1;

          //first button
          if (this.paginationdata.firstPage) {
            document.getElementById("first").disabled = false;
          } else {
            document.getElementById("first").disabled = true;
          }
          //prior button
          if (this.paginationdata.previousPage) {
            document.getElementById("prior").disabled = false;
          } else {
            document.getElementById("prior").disabled = true;
          }
          //next button
          if (this.paginationdata.nextPage) {
            document.getElementById("next").disabled = false;
          } else {
            document.getElementById("next").disabled = true;
          }
          //last button
          if (this.paginationdata.lastPage) {
            document.getElementById("last").disabled = false;
          } else {
            document.getElementById("last").disabled = true;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    whichPage(e) {
      //alert('clicked the button')
      if (e.target.id == "first") {
        this.filteroffset = this.paginationdata.firstPage;
        this.getLedgerlist();
      } else if (e.target.id == "prior") {
        this.filteroffset = this.paginationdata.previousPage;
        this.getLedgerlist();
      } else if (e.target.id == "next") {
        this.filteroffset = this.paginationdata.nextPage;
        this.getLedgerlist();
      } else if (e.target.id == "last") {
        this.filteroffset = this.paginationdata.lastPage;
        this.getLedgerlist();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.year-selector {
  width: 20%;
}
.entryttype-selector {
  width: 30%;
}
.check-btn {
  transform: scale(02);
  width: 12px;
  height: 12px;
}
// .invoice-filtercheck-select{
//     width: 40%;
// }
.disable_row {
  color: red !important;
}
.enable_row {
  color: green !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<!-- getLedgerlist() {
  this.mytoken = localStorage.getItem("token");

  var config = {
    method: "get",
    url:
      "https://engine.netsupport.dk:7080/account/v1/debtorentries/" +
      this.mytoken +
      "?offset=" +
      this.filteroffset +
      "&pagesize=" +
      this.perPage +
      "&year=" +
      this.filterYear +
      "&entrytype=" +
      this.filterEntryType +
      "&startdate=" +
      this.filterStartDate +
      "&enddate=" +
      this.filterEndDate,
  };
  axios(config)
    .then((response) => {
      //console.log(response.data.economic.collection);
      this.totalLedger = response.data.economic.pagination.results;
      this.ladgerdata = JSON.parse(
        JSON.stringify(response.data.economic.collection)
      );

      this.paginationdata = response.data.economic.pagination;

      this.currentPage = response.data.economic.pagination.skipPages + 1;

      //first button
      if (this.paginationdata.firstPage) {
        document.getElementById("first").disabled = false;
      } else {
        document.getElementById("first").disabled = true;
      }
      //prior button
      if (this.paginationdata.previousPage) {
        document.getElementById("prior").disabled = false;
      } else {
        document.getElementById("prior").disabled = true;
      }
      //next button
      if (this.paginationdata.nextPage) {
        document.getElementById("next").disabled = false;
      } else {
        document.getElementById("next").disabled = true;
      }
      //last button
      if (this.paginationdata.lastPage) {
        document.getElementById("last").disabled = false;
      } else {
        document.getElementById("last").disabled = true;
      }
    })
    .catch(function(error) {
      console.log(error);
    });
}, -->
